import React from "react";
import "./notice-banner.css";

import Marquee from "react-fast-marquee";

function NoticeBannerPlaygroup() {
  return (
    <div className="notice-banner">
      <Marquee gradient={false} speed={80}>
        <label className="notice-label">
          <b>
            Admissions for the academic year 2025 - 2026 are temporarily closed. Check again later.
          </b>
        </label>
      </Marquee>
    </div>
  );
}

export default NoticeBannerPlaygroup;
